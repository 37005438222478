<template>
    <div class="updatePassword" :style="'height: ' + windowHeight + 'px;'">
        <rxNavBar v-if="androidFlg == false" title="通讯录" androidOrIOSFlag="true"  backColor="true"  @stay="stay"></rxNavBar>
        <rxNavBar v-else title="通讯录"  backColor="true"  @stay="stay"></rxNavBar>
            <div class="search" v-if="staffFlag=='1'||staffFlag=='2'">
                <div class="search-icon"></div>
                <input type="text" placeholder="姓名/手机号/身份证搜索" v-model="searchValue" @keyup.enter='searchSubmit'>
            </div>
      <div >


      </div>
        <div class="department" v-if="staffFlag=='1'">
            <van-cell-group >
                <div v-for="item in departmentList" :key="item.id">
                  <div class="detailscontent" @click="enterInner(item)">
                    <div style="font-size: 15px;color: #101010">{{item.title}}</div>
                    <div style="font-size: 15px;color: #999999">></div>
                  </div>
<!--                    <van-cell :title="item.title" value=">" @click="enterInner(item)"/>-->
                </div>
            </van-cell-group>
            <div class="user" >
                <van-cell-group  >
                    <div v-for="item in staffInformationList" :key="item.id">
                        <div class="list" @click="checkDetails(item)">
                            <van-image fit="contain" width="40" height="40" :src="item.path" radius="5px"/>
                          <div >

                          <div style="margin-left: 15px;font-size: 15px;display: flex" >{{item.staffName}} <div class="positionTitle">{{item.dutyName}}</div></div>
                            <div style="margin-left: 15px;font-size: 10px;color: #999999;margin-top: 5px">{{item.departmentName}}</div>


                          </div>
                        </div>
                    </div>
                </van-cell-group>
            </div>
            <!--<div class="user" v-if="userList.staffName">
            <van-cell-group >
                    <div style='position:relative;border-radius: 100px;'>
                        <van-image width="30" height="30" :src="item.path"/>
                        <van-cell :title="userList.staffName" :label="userList.title"  @click="checkDetails(userList)"/>
                    </div>
            </van-cell-group>
            </div>-->
        </div>
        <div class="staff" v-else-if="staffFlag=='2'">
            <div  >
                <div v-for="item in staffInformationList" :key="item.id">
                  <div class="list"  @click="checkDetails(item)">

                    <van-image fit="contain" width="40" height="40" :src="item.path" radius="5px"/>
                    <div >

                      <div style="margin-left: 15px;font-size: 15px;display: flex" >{{item.staffName}} <div class="positionTitle">{{item.dutyName}}</div></div>
                      <div style="margin-left: 15px;font-size: 10px;color: #999999;margin-top: 5px">{{item.departmentName}}</div>


                    </div>
                  </div>
                </div>
                <div style="visibility:hidden;font-size:10px">1</div>
            </div>

        </div>
        <div class="details" v-else-if="staffFlag=='3'" >
            <div >
                    <div class="detailscontent">
<!--                      <p ></p>-->
<!--                      <div class="image" >-->
                        <van-image   fit="contain" :width="40" :height="40" :src="detailsInfo.path" radius="5px" @click="openImg()" class="head_sculpture"/>
<!--                      </div>-->

                      <div style="font-size: 15px;font-weight: bold;">{{detailsInfo.staffName}}</div>
<!--                        <van-cell :title="detailsInfo.staffName" />-->
                    </div>
            </div>
            <div class="company-name">辽宁海米信息科技有限公司</div>
            <div class="organization-information">组织信息</div>
            <div class="detailscontent">
                <span class="key">企业/组织</span>
                <span  class="value">辽宁海米</span>
            </div>
            <div class="detailscontent">
                <span class="key">姓名</span>
                <span class="value">{{detailsInfo.staffName}}</span>
            </div>
            <div class="detailscontent">
                <span class="key">员工编号</span>
                <span class="value">{{detailsInfo.employeeNumber}}</span>
            </div>
            <div class="detailscontent">
                <span class="key">部门</span>
                <span class="value">{{detailsInfo.departmentName}}</span>
            </div>
            <div class="detailscontent">
                <span class="key">性别</span>
                <span class="value">{{detailsInfo.gender}}</span>
            </div>
            <div class="detailscontent">
                <span class="key">职位</span>
                <span class="value">{{detailsInfo.dutyName}}</span>
            </div>
            <div class="detailscontent"  @click="showMobile(detailsInfo)">
                <span class="key">电话</span>
                <span class="value" >{{detailsInfo.mobile}}</span>
            </div>
            <div class="detailscontent">
                <span class="key">入职时间</span>
                <span class="value">{{detailsInfo.entryTime}}</span>
            </div>
            <div style="visibility:hidden;">1</div>
        </div>
      <!--拨打电话弹窗-->
      <dial-mobile
          :ownerMobile="temporaryOwnerMobile"
          :userName="temporaryOwnerName"
          :dialogTitle="dialogTitle"
          :mobileShow="isMobileShow"
          @hideDialog="hideDialog"
      >
      </dial-mobile>
    </div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup,
  Sticky,
  Tab,
  Tabs,
  Image as VanImage,
  Sidebar,
  SidebarItem,
  IndexBar,
  IndexAnchor,
  Cell,
  CellGroup,
  ImagePreview
} from 'vant'
    import {
        checkAndroid, checkIOS, getStaffId,
        globaluserId,
        money, openInWebview,
        responseUtil
    } from "../../../libs/rongxunUtil";
    import {
       queryStaffList,findDepartmentByStaffId,queryDepartment,queryDepartmentTreeList,browseStaffList
    } from "../../../getData/getData";

    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
        window.himi.closeWindow();
    }
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    export default {
        name: 'addressBook',
        components: {
            [NavBar.name]: NavBar,
            [NumberKeyboard.name]: NumberKeyboard,
            [Toast.name]: Toast,
            [Button.name]: Button,
            [Popup.name]: Popup,
            [Calendar.name]: Calendar,
            [DatetimePicker.name]: DatetimePicker,
            rxNavBar,
            [Sticky.name]: Sticky,
            [VanImage.name]: VanImage,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Sidebar.name]: Sidebar,
            [SidebarItem.name]: SidebarItem,
            [IndexBar.name]:IndexBar,
            [IndexAnchor.name]:IndexAnchor,
            [Cell .name]:Cell,
            [CellGroup .name]:CellGroup,
          [ImagePreview.Component.name]: ImagePreview.Component,
          dialMobile
        },
        data() {
            return {
                 androidFlg: '',
              temporaryOwnerMobile: '',
              temporaryOwnerName: '',
              dialogTitle: '联系电话',
              //拨打电话弹窗
              isMobileShow: false,
                userList:{},
                departmentList:[],           //员工集合
                index:1,                    //第几页
                staffFlag:'1',
                staffInformationList:[],    //部门员工列表
                detailsInfo:{},      //员工详细信息
                windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
                photo: require("../../../assets/images/1.png"),
                level:0,
                searchValue:''
            }
        },
        mounted() {
            //localStorage.clear()
            this.queryStaffList()
            this.checkPhoneorMobel()
        },
        methods: {
            checkPhoneorMobel() {
            if(checkAndroid()) {
                this.androidFlg = true
            }
            else if(checkIOS()) {
                this.androidFlg = false
            }
            },
          //放大图片
          openImg() {
            let arr = []
            arr.push(this.detailsInfo.path)
            ImagePreview({
              images: arr,
              // startPosition: j,
              onClose() {},
            })
          },
          // openImg() {
          //   ImagePreview({
          //     images:this.detailsInfo.staffName,
          //     closeable: true
          //   });
          // },
          //拨打电话
          showMobile(e) {
            console.log(e)
            let that = this
              that.temporaryOwnerMobile = e.mobile
              that.temporaryOwnerName = e.staffName
            that.isMobileShow = !that.isMobileShow
          },
          //关闭拨打电话弹窗
          hideDialog() {
            let that = this
            that.isMobileShow = false
          },
            //查询
            searchSubmit(){
              console.log(this.searchValue)
            if(this.searchValue !='' &&this.searchValue!= null && this.searchValue!= undefined){
              this.staffFlag='2'
              this.departmentStaff()
            }else {
              this.staffFlag='1'
              this.staffInformationList = {}
              this.queryStaffList()

            }

            },
            //所有员工
            queryStaffList(){
                let that=this
                let initData={}
                initData.user_id=globaluserId()
                initData.isOpen=false
                queryDepartmentTreeList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        //that.initParam.header = response.data.data
                        that.departmentList=response.data.data.departmentList
                    })
                })
                /*this.departmentList=[
                    {children:[{
                        children: [{
                            children: [{
                                children: [
                                    {cityName: "沈阳市",departmentLevelType: "1",departmentType_id: 12,id: 55,level: "5",levelTop: "52,53,54,113,55",pid: "113",staffName: "张枫卓",title: "嘉和旗舰店四部"},
                                    {cityName: "沈阳市",departmentLevelType: "1",departmentType_id: 12,id: 145,level: "5",levelTop: "52,53,54,113,145",pid: "113",staffName: "王昌盛",title: "嘉和旗舰店一部",},
                                    {cityName: "沈阳市",departmentLevelType: "1",departmentType_id: 12,id: 216,level: "5",levelTop: "52,53,54,113,216",pid: "113",staffName: "辛全金",title: "嘉和旗舰店三部",},
                                    {cityName: "沈阳市",departmentLevelType: "1",departmentType_id: 12,id: 245,level: "5",levelTop: "52,53,54,113,245",pid: "113",staffName: "赵越",title: "嘉和旗舰店二部",}
                                ],
                                cityName: "沈阳市",
                                departmentLevelType: "1",
                                departmentType_id: 12,
                                id: 113,
                                level: "4",
                                levelTop: "52,53,54,113",
                                pid: "54",
                                staffName: "毕超",
                                title: "嘉和旗舰店",
                            }],
                            cityName: "沈阳市",
                            departmentLevelType: "1",
                            departmentType_id: 12,
                            expand: true,
                            id: 54,
                            level: "3",
                            levelTop: "52,53,54",
                            pid: "53",
                            staffName: "毕超",
                            title: "中山大区",
                        }],
                        cityName: "沈阳市",
                        departmentLevelType: "1",
                        departmentType_id: 12,
                        expand: true,
                        id: 53,
                        level: "2",
                        levelTop: "52,53",
                        pid: "52",
                        staffName: "佟琳",
                        title: "营销中心"}],
                    cityName: "沈阳市",
                    departmentLevelType: "0",
                    departmentType_id: 175,
                    expand: true,
                    id: 52,
                    level: "1",
                    levelTop: "52",
                    pid: "ROOT",
                    staffName: "郑全",
                    title: "大连海米" ,
                    },
                    {
                        cityName: "沈阳市",
                        departmentLevelType: "0",
                        departmentType_id: 175,
                        expand: true,
                        id: 121,
                        level: "1",
                        levelTop: "121",
                        pid: "ROOT",
                        staffName: "魏士民",
                        title: "遇见酒店" ,
                    },
                    {
                        cityName: "首页",
                        departmentLevelType: "0",
                        departmentType_id: 175,
                        expand: true,
                        id: 124,
                        level: "1",
                        levelTop: "124",
                        pid: "ROOT",
                        staffName: "李博",
                        title: "沈阳海米",
                    },
                    {
                        cityName: "沈阳市",
                        departmentLevelType: "0",
                        departmentType_id: 175,
                        id: 182,
                        level: "1",
                        levelTop: "182",
                        pid: "ROOT",
                        staffName: "郑全",
                        title: "股东会",
                    }
                ]*/
            },
            departmentStaff(e){
                console.log(e)
                let that=this
                let initData={}
                initData.currentPage=1
                initData.numberPage=10000
                initData.staffStatus=''
                initData.department_id=e?e.id:''
                initData.user_id=getStaffId()
                initData.searchValue=that.searchValue
                initData.isOpen=false
                initData.isAll=true
                browseStaffList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        //that.initParam.header = response.data.data
                        that.staffInformationList=response.data.data.data
                        that.detailsInfo=that.staffInformationList?that.staffInformationList[0]:{}
                        if(e){
                            localStorage.setItem('userList'+e.level,JSON.stringify(that.staffInformationList))
                        }

                    })
                })
            },
            //返回
            stay(){
                console.log(this.staffFlag)
                console.log(this.index)
                if(this.staffFlag=='3'){
                    //--this.index
                    this.staffFlag='1'

                }
                else if(this.staffFlag=='2'){
                    --this.index
                    this.staffFlag='1'
                }else{
                    --this.index;
                    if(this.index==0){
                        this.$router.go(-1);
                        return
                    }
                }
                console.log(this.index)
                this.staffInformationList=localStorage.getItem('userList'+(this.index-1))!='undefined'?JSON.parse(localStorage.getItem('userList'+(this.index-1))):[]
                console.log(this.staffInformationList)
                let index=this.index?this.index:1
                this.departmentList=localStorage.getItem('level'+index)!='undefined'?JSON.parse(localStorage.getItem('level'+index)):[]
                console.log(this.departmentList)
                if(this.index<0){
                    this.$router.go(-1);
                    return
                }
                //let index=this.index-1
                //this.userList=JSON.parse(localStorage.getItem('userList'+(index)))
                //console.log(this.userList)
            },
            //下一页
            enterInner(e){
                this.staffInformationList = {}

                localStorage.setItem('level'+e.level,JSON.stringify(this.departmentList))
                //localStorage.setItem('userList'+e.level,JSON.stringify(e))
                this.departmentList=e.children
                this.level=Number(e.level)
                //this.userList=e
                if(!e.children){
                    this.staffFlag='2'

                    //let staffInforamtion=JSON.parse(localStorage.getItem('level'+--this.index))
                    //this.staffInformationList=staffInforamtion[0].children
                }  else{

                }
                this.index++;
                this.departmentStaff(e)
            },
            //详情
            checkDetails(e){
                console.log(e)
                this.staffFlag='3'
                this.detailsInfo=e
                localStorage.setItem('level'+(this.level+1),JSON.stringify(this.departmentList))
                //localStorage.setItem('userList'+e.level,JSON.stringify(e))
            }

        }
    }
</script>

<style lang="less" scoped>


.image{
width: 40px;
height: 40px;
  padding: 10px;
  //position: relative;
  //z-index: 1;
//
}
.head_sculpture{
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //width: 100%;

}
.positionTitle{
  margin-left: 5px;
  font-size: 5px!important;
  color: #ffa834;
  border: 1px solid #ffa834;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
    .list{
      display: flex;
      align-items: center;
      //justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #f2f2f2;
    }
    .detailscontent{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #f2f2f2;
    }
    .search{
        margin: 20px 20px 10px 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .user{
        .van-image{
            //position:absolute;
            //top:8px;
            //left:20px;
            //z-index:1000;
            border-radius: 50%;
        }
        .van-cell{
            position:relative;
            left:40px;
            font-size:11px;
            font-weight: bold;
            padding:5px 15px;
        }
        .van-cell__label{
            margin-top:0;
            font-size: 7px;
            font-weight: normal;
        }
    }
    .staff{
        .van-image{
            //position:absolute;
            //top:10px;
            //left:20px;
            //z-index:1000;
            border-radius: 50%;
        }
        .van-cell{
            position:relative;
            left:40px;
            font-size:11px;
            font-weight: bold;
            padding:5px 15px;
        }
        .van-cell__label{
            margin-top:0;
            font-size: 7px;
            font-weight: normal;
        }
    }

    .details{
      .detailscontent{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 1px solid #f2f2f2;
      }
        background:white;
        .van-image{
            //position:absolute;
            //top:10px;
            //left:20px;
            //z-index:1000;
            border-radius: 50%;
        }
        .van-cell{
            position:relative;
            left:55px;
            top:-5px;
            font-size:14px;
            font-weight: bold;
            padding:15px;
        }
        .van-cell__label{
            margin-top:0;
            font-size: 7px;
            font-weight: normal;
        }
        .company-name{
            font-size:11px;
            padding:15px 15px;
            color:#3da5ef;
          border-bottom: 1px solid #f2f2f2;
        }
        .organization-information{
            font-size:14px;
            font-weight: bold;
            padding:15px 15px;
          border-bottom: 1px solid #f2f2f2;
        }
        .key{
            font-size:13px;
            color:grey;
            //margin:8px 20px;
        }
        .value{
            font-size:13px;
        }
    }
</style>
